import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "./App.css";
import "./styles/media-query.css";
import TokenLogin from "./pages/login/TokenLogin";
import Home from "./pages/home/Home";
import SingleEvent from "./pages/single-event/SingleEvent";
import ThanksPage from "./pages/thanks/thanksPage";
import ScrollToTop from "./helpers/ScrollTop";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations.json";
import AuthorizedLayout from "./layout/AuthorizedLayout";
import Logo from "./components/Logo";
import Lifestyle from "./pages/lifestyle/Lifestyle";
import DealDetail from "./components/DealDetail";
import ThanksDealPage from "./pages/ThanksDealPage";
import EmailLogin from "./pages/login/EmailLogin";
import PhoneLogin from "./pages/login/PhoneLogin";
import CreateAccount from "./pages/login/CreateAccount";

import { supabase } from "./supabaseClient";
import { useDispatch, useSelector } from "react-redux";
import { changeToken, selectUser, setUser } from "./slices/userReducer";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import AdminLayout from "./layout/AdminLayout";
import ManageEvents from "./pages/ManageEvents";
import ManageDeals from "./pages/ManageDeals";
import ManageVenues from "./pages/ManageVenues";
import Profile from "./pages/Profile";
import ProfileInfo from "./pages/ProfileInfo";
import ProfileEvents from "./pages/ProfileEvents";
import ChooseGenres from "./pages/ChooseGenres";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { availableGenres as genres } from "./helpers/helpers";
import ManageEventDetail from "./pages/ManageEventDetail";
import ManageCities from "./pages/ManageCities";

function App() {
  const navigate = useNavigate();
  const [eventsLoading, setEventsLoading] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [eventData, setEventData] = useState([]);
  // extract token from url
  const location = useLocation();
  const getTokenFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("token"); // Assuming the URL param is named 'token'
  };

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || navigator.language.split("-")[0]
  );

  const [deals, setDeals] = useState([]);

  i18n.use(initReactI18next).init({
    resources: translations,
    lng: language,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });

  const fetchToken = () => {
    const token = localStorage.getItem("token") || getTokenFromUrl();

    if (token) {
      dispatch(changeToken(token));
    }
  };

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  // Whenever the user updates, fetch the events
  useEffect(() => {
    const getEvents = async () => {
      const events = await supabase.from("Events").select(`
      *,
      venue:Venue (
        id,
        name
      )`);

      setEventData(events.data);
      setEventsLoading(false);
    };

    const getDeals = async () => {
      const deals = await supabase.from("Deals").select("*");
      setDeals(deals.data);
    };

    if (user.token) {
      getDeals();
      getEvents();
    }
  }, [user]);

  // Try to fetch the user.
  // If you cant fetch the user, then try to fetch the token from localstorage.
  useEffect(() => {
    supabase.auth
      .getUser()
      .then((res) => {
        const auth_user = res.data.user;
        const user_info = auth_user.user_metadata;
        setIsLoading(false);

        if (!user_info) {
          navigate("/login");
          return;
        }

        // Check if the user has a User table entry. if not, create one.
        supabase
          .from("User")
          .select("*")
          .eq("auth_user_id", auth_user.id)
          .single()
          .then((response) => {
            if (response.error) {
              // Check if the user already exists

              supabase
                .from("User")
                .select("*")
                .eq("email", auth_user.email)
                .limit(1)
                .single()
                .then((potential_user) => {
                  if (!potential_user.data) {
                    supabase
                      .from("User")
                      .insert({
                        email: auth_user.email,
                        phone_number: user_info.phone_number,
                        auth_user_id: auth_user.id,
                      })
                      .then((out) => {});
                  }
                });
            }
          });

        if (!res.error) {
          dispatch(
            setUser({
              id: auth_user.id,
              email: user_info.email,
              phone_number: user_info.phone_number,
              first_name: user_info.first_name,
              last_name: user_info.last_name,
              date_of_birth: user_info.date_of_birth,
              location: user_info.location,
              gender: user_info.gender,
              created_at: user_info.created_at,
              token: user_info.token,
              role: auth_user.role,
              genres: user_info.genres,
            })
          );

          // Get the events
        } else {
          // Try to fetch the token;
          fetchToken();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        fetchToken();
        // Try to fetch the token
      });
  }, [location]);

  if (isLoading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center hero-section">
        <Logo />
      </div>
    );
  }

  return (
    <>
      <ChakraProvider theme={"dark"}>
        <ScrollToTop />
        <Routes>
          <Route path="login" element={<TokenLogin setLanguage={setLanguage} />} />
          <Route path="email-login" element={<EmailLogin setLanguage={setLanguage} />} />
          <Route path="phone-login" element={<PhoneLogin />} />
          <Route path="create-account" element={<CreateAccount setLanguage={setLanguage} />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="verify-email" element={<VerifyEmailPage />} />
          <Route path="choose-genres" element={<ChooseGenres setLanguage={setLanguage} />} />
          <Route
            path="/"
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <Home
                  token={null}
                  eventData={eventData}
                  genres={genres}
                  eventsLoading={eventsLoading}
                  setEventsLoading={setEventsLoading}
                  language={language}
                  setLanguage={setLanguage}
                />
              </AuthorizedLayout>
            }
          />
          <Route
            path="single-event"
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <SingleEvent
                  token={user.token}
                  eventData={eventData}
                  language={language}
                  setLanguage={setLanguage}
                />
              </AuthorizedLayout>
            }
          />
          <Route
            path="deal"
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <DealDetail
                  dealData={deals}
                  language={language}
                  setLanguage={setLanguage}
                />
              </AuthorizedLayout>
            }
          />
          <Route
            path="lifestyle"
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <Lifestyle
                  deals={deals}
                  setLanguage={setLanguage}
                  language={language}
                />
              </AuthorizedLayout>
            }
          />

          <Route
            path="profile"
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <Profile language={language} setLanguage={setLanguage} />
              </AuthorizedLayout>
            }
          />

          <Route
            path="profile/me"
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <ProfileInfo language={language} setLanguage={setLanguage} />
              </AuthorizedLayout>
            }
          />

          <Route
            path="profile/events"
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <ProfileEvents language={language} setLanguage={setLanguage} />
              </AuthorizedLayout>
            }
          />

          <Route path="thanks" element={<ThanksPage />} />
          <Route path="thanksDeal" element={<ThanksDealPage />} />

          <Route
            path="/admin/events"
            element={
              <AdminLayout>
                <ManageEvents />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/event/:id"
            element={
              <AdminLayout>
                <ManageEventDetail />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/lifestyle"
            element={
              <AdminLayout>
                <ManageDeals />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/venues"
            element={
              <AdminLayout>
                <ManageVenues />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/cities"
            element={
              <AdminLayout>
                <ManageCities />
              </AdminLayout>
            }
          />
        </Routes>
      </ChakraProvider>
    </>
  );
}

export default App;
